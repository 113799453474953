import React, { useCallback, useMemo, useState, useEffect } from "react";
import ButtonLogic from "./ButtonLogic";
import { generateUrlAndReport } from "../services/TestLink";
import { useParams } from "react-router-dom";
import userData from "../hooks/useData";
import Attempt from "./Attempt";
import { convertToISTWithTime } from "../services/utils";
import { toast } from "react-toastify";
import { useLoaderAndToast } from "../ToastProvider";

const AssessmentReport = ({ report, activity }) => {
  const { user } = userData();
  const { programId, moduleId } = useParams();
  const { enableLoader } = useLoaderAndToast();
  const [data, setData] = useState(report);
  const [report_username, setReportUsername] = useState(null); 
  const [report_passwd, setReportPasswd] = useState(null); 
  const total_attempts_allowed = activity.data.no_of_attempt;

  const findMaxAttempt = useCallback((reportData) => {
    const maxAttempt =
      reportData.length > 0
        ? Math.max(
            ...reportData.map((item) => parseInt(item.number_of_attempt))
          ) || 0
        : 0;
    return maxAttempt;
  }, []);

  useEffect(() => {
    const storedData = localStorage.getItem('user');
    if (storedData) {
      setReportUsername(JSON.parse(storedData).report_viewer_username);
      setReportPasswd(JSON.parse(storedData).report_viewer_passwd);
    }
  }, []);

  const handleClick = async () => {
    const loginUrl = data.auto_login_url ?? "";

    if (
      loginUrl === "" &&
      data.report_data.length < parseInt(total_attempts_allowed, 10)
    ) {
      enableLoader({
        flag: true,
      });
      const response = await generateUrlAndReport({
        activity_id: activity._id,
        email_id: user.email_id,
        learner_id: user._id,
        learner_name: user.learner_name,
        module_id: moduleId,
        organization_id: user.organization_id,
        program_id: programId,
        super_admin: user.super_admin,
        test_id: activity.data.test_id,
      });
      const assessmentURL = response?.data?.data?.auto_login_url;
      if (response && response?.data && response?.data?.data && assessmentURL) {
        setData(response.data.data);
        window.open(assessmentURL, "_blank");
        localStorage.setItem('localAssessmentData', JSON.stringify(response.data.data)); // Save to local storage
      } else {
        toast.error("Error in scheduling Assessment");
      }
      enableLoader({
        flag: false,
      });
    } else if (loginUrl !== "") {
      window.open(loginUrl, "_blank");
    } else {
      alert(`Only ${total_attempts_allowed} Attempts Allowed`);
    }
  };

  // Button data logic
  const buttonData = useMemo(() => {
    let maxAttempt = findMaxAttempt(data.report_data);
    const remainingAttempts = parseInt(total_attempts_allowed, 10) - maxAttempt;

    const currentDateTime = new Date().toISOString();
    const startDate = new Date(activity.activity_start_date);
    const endDate = new Date(activity.activity_end_date);

    const reportDataLength = data.report_data.length;

    const btnState = ButtonLogic(
      remainingAttempts,
      currentDateTime,
      startDate,
      endDate,
      reportDataLength
    );
    return btnState;
  }, [
    activity.activity_end_date,
    activity.activity_start_date,
    data.report_data,
    findMaxAttempt,
    total_attempts_allowed,
  ]);

  return (
    <div
      className={`${
        buttonData.disabled ? "from-expired" : "from-blue-500"
      } bg-gradient-to-r bg-white border-gradient-to-l flex flex-col gap-4 p-8 rounded shadow-md`}
    >
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-4">
          <div className="flex justify-start flex-col">
            <div className="text-xl">
              Assessment Name:{" "}
              <span className="font-bold">
                {activity.data.test_name ?? "-"}
              </span>
            </div>
            <div className="text-xl">
              Assessment No: {activity.data.test_id}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex h-fit">
              <p className="font-normal ">Start Date:</p>
              <p className="  font-bold px-2">
                {convertToISTWithTime(activity.activity_start_date)}
              </p>
            </div>

            <div className="flex h-fit ml-5">
              <p className="font-normal ">End Date:</p>
              <p className=" font-bold px-2">
                {convertToISTWithTime(activity.activity_end_date)}
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-start justify-between">
          <button
            disabled={buttonData.disabled}
            className={`${
              buttonData.disabled
                ? "from-expired via-grey to-expired cursor-not-allowed"
                : "from-blue-500 via-fuchsia-500 to-btn-suc"
            } bg-gradient-to-r p-1 rounded-full`}
            onClick={handleClick}
          >
            <span className="block text-black px-4 py-2 font-semibold rounded-full bg-white hover:bg-transparent hover:text-white transition">
              {buttonData.buttonText}
            </span>
          </button>
        </div>
      </div>

      {data.report_data.length !== 0 && (
        <div>
          {data["report_data"].map((item) => (
            <Attempt key={item["attempt_number"]} data={item} />
          ))}
        </div>
      )}
      {report_username  && (
        <div className=" rounded flex flex-row items-end">
          <h3 className="text-lg font-semibold">Report Viewer Username: </h3>
          <pre>{  report_username}</pre>
        </div>
      )}
      {report_passwd  && (
        <div className=" rounded flex flex-row items-end">
          <h3 className="text-lg font-semibold">Report Viewer Password: </h3>
          <pre>{  report_passwd}</pre>
        </div>
      )}
    </div>
  );
};

export default AssessmentReport;
