import React, { useEffect, useState }  from "react";
import { FaDownload } from "react-icons/fa";
import { ProgressBar } from "./ProgressBar";
import { HiMiniViewfinderCircle } from "react-icons/hi2";
import axios from "axios";
import {API_URL} from "../services/index"
const Attempt =   ({ data }) => {
  return (
    <div className="my-3">
      <div className="w-fit flex justify-start bg-[#D9D9D9] p-2 rounded-md mb-1">
        <span className="mr-2">Status: </span>
        <span>
          {data["Participant status"] ? data["Participant status"] : "-"}
        </span>
      </div>
      <div className="bg-[#D9D9D9] flex flex-col gap-2 rounded-md">
        <div className="flex justify-between">
          <div className="flex flex-col items-center p-4">
            <p>Attempt No.</p>
            <p className="text-3xl">{data["attempt_number"]}</p>
          </div>
          {data["Code Playback Link"] && (
            <div className="flex flex-row items-center p-4">
              <p>View Report</p>
              <HiMiniViewfinderCircle
                onClick={() => {
                  window.open(data["Code Playback Link"], "_blank");
                }}
                className="w-12 cursor-pointer" 
                aria-label="View Report"
              />
            </div>
          )}
          <div className="flex flex-row items-center p-4">
            <p>Download Report</p>
            <FaDownload
              onClick={async () => {
                const responce = await axios.post(`${API_URL}/getReportFile`,{
                    'AMCAT ID': data['AMCAT ID']
                  })
                let reportUrl = responce.data.reportUrl
                window.open(reportUrl, "_blank");
              }}
              className="w-12"
            />
          </div>
        </div>
        <div className="flex">
          {Object.keys(data).includes("score")
            ? Object.keys(data["score"]).map((keyName) => {
                return (
                  <div className="p-4 rounded" key={keyName}>
                    <p>{keyName}</p>
                    <ProgressBar status={data["score"][keyName]} />
                    {/* <p className="text-xl">{data["score"][keyName]}</p> */}
                  </div>
                );
              })
            : "-"}
        </div>
      </div>
    </div>
  );
}; 
export default Attempt;
